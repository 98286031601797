import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from "axios"
import ElementPlus, { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging'
import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import RestApiService from './service/RestApiService'
import store from './store'
import { FIREBASE_CONFIG } from '@/config/FirebaseConfig'


if (VueCookieNext.getCookie('user')) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + VueCookieNext.getCookie("user").token;
}


const firebaseApp = initializeApp(FIREBASE_CONFIG)
// const messaging = getMessaging(firebaseApp)


Notification.requestPermission((permission: NotificationPermission) => {
  console.log('permission', permission);

})

// getToken(messaging, {
//   vapidKey:
//     "BKbSH3pBxXq_74Dj1f9CWeogYvZ9Mdhf9944Ug6W88_vPXwZ3QVfxs62vNaGtkRSxxqd9qB6OJ8QEQxmXiihv4w",
// }).then((token) => {
// console.log('main file fb token: ', token);
// });


const app = createApp(App).use(store).use(router).use(VueCookieNext).use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app')

