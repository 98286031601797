import RestApiService from '@/service/RestApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { createStore } from 'vuex'

export default createStore({
  state: {
    totalCounter: {
      countIn: 1,
      countOut: 1,
      countStay: 1
    }
  },
  getters: {
    getCounter: (state) => state.totalCounter
  },
  mutations: {
    totalInOutStay(state, data) {
      state.totalCounter = data
    }
  },
  actions: {
  },
  modules: {
  }
})
