import { tr } from 'element-plus/es/locale'
import { VueCookieNext } from 'vue-cookie-next'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/about',
    name: 'about',
    component: () => import('../views//setting/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginUser.vue')
  },

  {
    path: '/',
    // meta: { requiresAuth: true },
    component: () => import('../views/MainView.vue'),
    children: [
      {

        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboard/DashboardView.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },
      {

        path: '/counting',
        name: 'counting',
        component: () => import('../views/dashboard/CountingView.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },
      {
        path: '/lineAge',
        name: 'lineAge',
        props: true,
        component: () => import('../components/charts/LineChartAge.vue')
      },
      // {
      //   path: '/userCreate/:companyName',
      //   name: 'userCreate',
      //   props: true,
      //   component: () => import('../views/user/UserCreateByCompany.vue'),
      //   // meta: { requiresAuth: true, admin: true, superAdmin: false },
      // },
      {
        path: '/userCreate',
        name: 'userCreate',
        component: () => import('../views/user/UserCreate.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },
      {
        path: '/updateUser/:id',
        name: 'updateUser',
        props: true,
        component: () => import('../views/user/UserUpdate.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },
      {
        path: '/changePassword/:id',
        name: 'changePassword',
        props: true,
        component: () => import('../views/account/ChangePassword.vue')
      },
      {
        path: '/addDevice',
        name: 'addDevice',
        component: () => import('../views/device/DeviceCreate.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/updateDevice/:id',
        name: 'updateDevice',
        props: true,
        component: () => import('../views/device/DeviceUpdate.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/listDevice',
        name: 'listDevice',
        component: () => import('../views/device/DeviceList.vue')
      },
      {
        path: '/listDisableDevice',
        name: 'listDisableDevice',
        component: () => import('../views/device/DisableDeviceList.vue')
      },
      {
        path: '/inOutLog',
        name: 'inOutLog',
        component: () => import('../views/device/InOutCounting.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import('../views/setting/SettingView.vue')
      },
      {
        path: '/report',
        name: 'report',
        component: () => import('../views/report/ReportView.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },
      {
        path: '/userlist',
        name: 'userlist',
        component: () => import('../views/user/UserList.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },
      {
        path: '/deactiveUserlist',
        name: 'deactiveUserlist',
        component: () => import('../views/user/DisableUserList.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },
      {
        path: '/accountProfile',
        name: 'accountProfile',
        component: () => import('../views/account/AccountProfile.vue'),
      },
      {
        path: '/userProfile/:id',
        name: 'userProfile',
        props: true,
        component: () => import('../views/user/UserProfile.vue'),
      },
      {
        path: '/updateProfile/:id',
        name: 'updateProfile',
        props: true,
        component: () => import('../views/account/AccountEditProfile.vue'),
        // meta: { requiresAuth: true, admin: true, superAdmin: false },
      },

      {
        path: '/companyList',
        name: 'companyList',
        component: () => import('../views/company/CompanyList.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/deactiveCompanyList',
        name: 'deactiveCompanyList',
        component: () => import('../views/company/DeactiveCompanyList.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/addCompany',
        name: 'addCompany',
        component: () => import('../views/company/CreateCompany.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/updateCompany/:id',
        name: 'updateCompany',
        props: true,
        component: () => import('../views/company/CompanyUpdate.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/accountByCompanyProfile/:id',
        name: 'accountByCompanyProfile',
        props: true,
        component: () => import('../views/account/AccountProfileByCompany.vue'),
      },
      {
        path: '/outletList',
        name: 'outletList',
        component: () => import('../views/outlet/OutletList.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/deactiveOutletList',
        name: 'deactiveOutletList',
        component: () => import('../views/outlet/DisableOutletList.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/addOutlet',
        name: 'addOutlet',
        component: () => import('../views/outlet/OutletCreate.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/updateOutlet/:id',
        name: 'updateOutlet',
        props: true,
        component: () => import('../views/outlet/OutletUpdate.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/listBusiness',
        name: 'listBusiness',
        component: () => import('../views/business/BusinessList.vue')
      },
      {
        path: '/listDeactivateBusiness',
        name: 'listDeactivateBusiness',
        component: () => import('../views/business/BusinessListDeactivate.vue')
      },
      {
        path: '/addBusiness',
        name: 'addBusiness',
        component: () => import('../views/business/BusinessCreate.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },
      {
        path: '/updateBusiness/:id',
        name: 'updateBusiness',
        props: true,
        component: () => import('../views/business/BusinessUpdate.vue'),
        // meta: { requiresAuth: true, admin: false, superAdmin: true },
      },

    ],

  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const authUser = VueCookieNext.getCookie('user')

  if (to.meta.requiresAuth) {

    if (!authUser || !authUser.token) {

      next('/login')

    }
    // else if (to.meta.superAdmin) {

    //   if (authUser.role[0] == 'super admin') {
    //     next()
    //   }
    // } else if (to.meta.admin) {

    //   if (authUser.role[0] === 'admin') {
    //     next()
    //   }
    // } else if(to.meta.user){
    //   if (authUser.role[0] === 'user') {
    //     next()
    //   }
    // }
    else {
      next()
    }

  } else {
    next()
  }
})



export default router
