<template>
  <div id="app" style="height: 100%">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
html,
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background: white;
  // background-image: url("https://uploads.codesandbox.io/uploads/user/c3fb8e8a-35ea-4232-b5d6-0f3c5373510b/LVs7-dots.png");
  background-size: contain;
  height: 100%;
}
.footer,
.header {
  font-family: Roboto, sans-serif;
  color: #f0f4f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  h1,
  h2 {
    font-family: Roboto, sans-serif;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h3 {
    color: #f0f4f8;
    padding: 0;
    margin: 0;
  }
  .links {
    display: flex;
    font-family: Roboto, sans-serif;
    span {
      padding: 0 10px;
      font-size: 18px;
      border-right: 1px solid #9fb3c8;
      &:last-child {
        border-right: none;
      }
    }
  }
  .version {
    font-family: "Open Sans";
    padding: 0 10px;
    color: #9fb3c8;
    font-size: 12px;
    margin-top: 5px;
  }
}
.header {
  padding: 10px 20px;
  .logo {
    font-family: Roboto, sans-serif;
    letter-spacing: 3px;
    padding-top: 60px;
    padding-bottom: 15px;
  }
  .logo .part-2 {
    color: #d64545;
  }
}
</style>
